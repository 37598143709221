require('dotenv').config({
  path: `.env.${process.env.NODE_ENV}`,
})
var STORYBLOK_ACCESS_TOKEN = process.env.GATSBY_STORYBLOK_ACCESS_TOKEN

var isProd = process.env.CONTEXT
  ? ['production'].includes(process.env.CONTEXT)
  : process.env.NODE_ENV === 'production'

let plugins = [
  'gatsby-plugin-styled-components',

  'gatsby-plugin-remove-serviceworker',
  'gatsby-transformer-sharp',
  'gatsby-transformer-remark',
  'gatsby-plugin-sharp',
  {
    resolve: 'gatsby-plugin-robots-txt',
    options: {
      resolveEnv: () => process.env.NODE_ENV,
      env: {
        production: {
          policy: [{ userAgent: '*' }],
        },
        'branch-deploy': {
          policy: [{ userAgent: '*', disallow: ['/'] }],
          sitemap: null,
          host: null,
        },
        'deploy-preview': {
          policy: [{ userAgent: '*', disallow: ['/'] }],
          sitemap: null,
          host: null,
        },
      },
    },
  },
  {
    resolve: 'gatsby-plugin-sitemap',
    options: {
      exclude: ['/404', '/404.html', '/editor'],
      query: `
        {
          site {
            siteMetadata {
              siteUrl
            }
          }
          allSitePage {
            edges {
              node {
                context {
                  noIndex
                }
                path
              }
            }
          }
      }`,
      serialize: ({ site, allSitePage }) =>
        allSitePage.edges.reduce((acc, { node }) => {
          const skipPage = node.context && node.context.noIndex
          if (!skipPage) {
            acc.push({
              url: `${site.siteMetadata.siteUrl}${node.path}`,
            })
          }
          return acc
        }, []),
    },
  },
  'gatsby-plugin-react-head',
  {
    resolve: 'gatsby-plugin-manifest',
    options: {
      name: 'Avenues',
      short_name: 'str',
      start_url: '/',
      background_color: '#fff',
      theme_color: '#13233C',
      display: 'minimal-ui',
      icon: 'static/assets/android-icon-192x192.png', // This path is relative to the root of the site.
      include_favicon: true, // Include favicon
    },
  },
  {
    resolve: 'gatsby-source-storyblok',
    options: {
      accessToken: STORYBLOK_ACCESS_TOKEN,
      homeSlug: '/home',
      version: isProd ? 'published' : 'draft',
    },
  },
  {
    resolve: 'gatsby-plugin-google-tagmanager',
    options: {
      id: process.env.GTM_ID,
    },
  },
  'gatsby-plugin-storyblok-image',
  'gatsby-plugin-loadable-components-ssr',
  {
    resolve: 'gatsby-plugin-netlify',
    options: {
      headers: {
        '/*': [
          'Content-Security-Policy: frame-ancestors https://*.storyblok.com/',
          'X-Frame-Options: ALLOW-FROM https://app.storyblok.com/',
        ],
      },
      mergeSecurityHeaders: true,
    },
  },
  {
    resolve: 'gatsby-plugin-webpack-bundle-analyzer',
    options: {
      production: true,
      disable: !process.env.ANALYZE_BUNDLE,
      generateStatsFile: true,
      analyzerMode: 'static',
    },
  },
]

if (process.env.GATSBY_CHINA_DEPLOY) {
  plugins = [
    ...plugins,
    {
      resolve: 'gatsby-plugin-baidu-analytics',
      options: {
        siteId: process.env.BAIDU_SITE_ID,
        head: false,
      },
    },
    'gatsby-plugin-symphony-analytics',
  ]
}

if (process.env.BRANCH === 'master') {
  plugins = [
    ...plugins,
    {
      resolve: 'gatsby-plugin-algolia',
      options: {
        appId: process.env.GATSBY_ALGOLIA_APP_ID,
        apiKey: process.env.ALGOLIA_ADMIN_KEY,
        queries: require('./src/utils/algolia_queries'),
        chunkSize: 10000, // default: 1000
      },
    },
  ]
}

module.exports = {
  assetPrefix: process.env.ASSET_PREFIX || '/',
  siteMetadata: {
    name: 'Avenues',
    siteUrl: process.env.URL,
    title: 'Avenues: The World School | Avenues - Private School',
    description:
      'Avenues: The World School. International school in New York City. Global education for high school, middle school, lower school and nursery.',
    seo: {
      separator: '|',
      og: {
        // fbAppId: '123',
        type: 'website',
        // image: '/img/og-cover.png',
      },
      twitter: {
        creator: '@Avenues_org',
        card: 'summary_large_image',
      },
    },
  },
  plugins: plugins,
}
