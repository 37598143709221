const enQuery = `{
  pages: allStoryblokEntry(filter: {name: {regex: "/^((?!Layout|404|error|thank you).)*$/i"}, lang: {eq: "default"}}) {
    edges {
      node {
        id
        lang
        name
        fields {
          full_slug
        }
        content
      }
    }
  }
}`
const ptQuery = `{
  pages: allStoryblokEntry(filter: {name: {regex: "/^((?!Layout|404|error|thank you).)*$/i"}, lang: {eq: "pt"}}) {
    edges {
      node {
        id
        lang
        name
        fields {
          full_slug
        }
        content
      }
    }
  }
}`
const zhQuery = `{
  pages: allStoryblokEntry(filter: {name: {regex: "/^((?!Layout|404|error|thank you).)*$/i"}, lang: {eq: "zh"}}) {
    edges {
      node {
        id
        lang
        name
        fields {
          full_slug
        }
        content
      }
    }
  }
}`

function parsePageEntries(edges) {
  return edges.map(entry => {
    const pageContent = JSON.parse(entry.node.content)
    const pageSeo = pageContent.seo ? pageContent.seo[0] : {}
    const pageKeywords = pageContent.search_keywords || ''

    const pageData = {
      path: entry.node.fields.full_slug,
      seoTitle: pageSeo.title,
      seoDescription: pageSeo.description,
      // component: pageContent.component,
      pageKeywords,
      ...(pageContent.component === 'news' && {
        title: pageContent.title,
        // body: pageContent.body,
      }),
    }

    return pageData
  })
}

const queries = [
  {
    query: enQuery,
    transformer: ({ data }) => parsePageEntries(data.pages.edges),
    indexName: 'PAGES_DEFAULT',
    settings: {},
  },
  {
    query: ptQuery,
    transformer: ({ data }) => parsePageEntries(data.pages.edges),
    indexName: 'PAGES_PT',
    settings: {},
  },
  {
    query: zhQuery,
    transformer: ({ data }) => parsePageEntries(data.pages.edges),
    indexName: 'PAGES_ZH',
    settings: {},
  },
]
module.exports = queries
